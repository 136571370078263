import { LicenceCommit, LicenceType } from "./types";

export const DEFAULT_NB_STORES: number = 50;

export const MIN_STORES: number = 10;
export const MAX_STORES: number = 500;

const CURRENCY: string = "EUR";

export const INTL_FORMATTER_1_DIGIT: Intl.NumberFormat = new Intl.NumberFormat(
    "fr-FR",
    {
        style: "currency",
        currency: CURRENCY,
        maximumFractionDigits: 1,
    }
);
export const INTL_FORMATTER_0_DIGIT: Intl.NumberFormat = new Intl.NumberFormat(
    "fr-FR",
    {
        style: "currency",
        currency: CURRENCY,
        maximumFractionDigits: 0,
    }
);

/**
 * Formats a number with or without a decimal point based on whether it is rounded.
 *
 * @param {number} value - The input number to be formatted.
 * @returns {string} The formatted string representation of the input number.
 */
export const formatNumberWithRounding = (value: number): string => {
    // Rounds the input number to one decimal 
    const valueWith1Decimal: number = Math.round(value * 10) / 10;

    // Checks if the rounded value has a decimal component
    if (valueWith1Decimal - Math.floor(valueWith1Decimal) > 0) {
        // Returns the formatted value with one decimal 
        return (INTL_FORMATTER_1_DIGIT.format(valueWith1Decimal));
    }
    // Returns the formatted value without decimal
    return (INTL_FORMATTER_0_DIGIT.format(valueWith1Decimal));
};

export interface PricingFeature {
    readonly name: string;
    readonly description?: string;
    readonly extraPricing?: number;
}

const DEFAULT_OPTIONS: Array<PricingFeature> = [
    {
        name: "FeatureB_real_time",
        description: "FeatureB_real_time_description"
    },
    {
        name: "FeatureB_view_daily_weekly_monthly",
        description: "FeatureB_view_daily_weekly_monthly_description"
    },
    {
        name: "FeatureB_objective",
        description: "FeatureB_objective_description"
    },
    {
        name: "FeatureB_comparison_daily_weekly_monthly",
        description: "FeatureB_comparison_daily_weekly_monthly_description"
    },
    {
        name: "FeatureB_gamification",
        description: "FeatureB_gamification_description"
    },
    {
        name: "FeatureB_nudge",
        description: "FeatureB_nudge_description"
    },
];

const PREMIUM_OPTIONS: Array<PricingFeature> = [
    {
        name: "FeatureP_all_previous",
    },
    {
        name: "FeatureP_weather",
        description: "FeatureP_weather_description",
    },
    {
        name: "FeatureP_forecast",
        description: "FeatureP_forecast_description",
    },
    {
        name: "FeatureP_periods",
        description: "FeatureP_periods_description",
    },
    {
        name: "FeatureC_SSO",
        description: "FeatureC_SSO_description",
        extraPricing: 5
    },
    {
        name: "FeatureC_shifts",
        description: "FeatureC_shifts_description",
        extraPricing: 10
    }
];

const ENTREPRISE_OPTIONS: Array<PricingFeature> = [
    {
        name: "FeatureE_all_previous"
    },
    {
        name: "FeatureE_KPIs",
        description: "FeatureE_KPIs_description"
    },
    {
        name: "FeatureE_challenges",
        description: "FeatureE_challenges_description"
    },
    {
        name: "FeatureC_POS",
        description: "FeatureC_POS_description",
        extraPricing: 10

    },

];

export const BASE_PRICES: Record<LicenceCommit, Record<LicenceType, number>> = {
    [LicenceCommit.MONTHLY]: {
        [LicenceType.STARTER]: 15,
        [LicenceType.PREMIUM]: 30,
        [LicenceType.ENTERPRISE]: 50,
    },
    [LicenceCommit.YEARLY]: {
        [LicenceType.STARTER]: 12,
        [LicenceType.PREMIUM]: 24,
        [LicenceType.ENTERPRISE]: 40,
    },
};

export const OPTIONS: Record<LicenceType, Array<PricingFeature>> = {
    [LicenceType.STARTER]: DEFAULT_OPTIONS,
    [LicenceType.PREMIUM]: PREMIUM_OPTIONS,
    [LicenceType.ENTERPRISE]: ENTREPRISE_OPTIONS,
};
