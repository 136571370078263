import {
    english,
    french
} from "./languages";
import i18n from "i18next";

export const SUPPORTED_LANGUAGES: Array<string> = [
    "fr", "en"
];

const DEFAULT_LANGUAGE: string = "fr";

// configuration for i18n
export const i18nOptions: any = {
    // debug: true,
    resources: {
        en: {
            translation: english
        },
        fr: {
            translation: french
        },
        // translations are added bellow
    },
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,

    interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
};

/**
 * Change i18n language to use
 * @param language 
 */
export const changeLanguage = (languageId: string): void => {
    i18n.changeLanguage(languageId);
};

/**
 * Return the current displayed language
 * @returns 
 */
export const getCurrentLanguage = (): string => {
    return (i18n.language);
}