import { useRef, useEffect } from "react";
import { getImage, getVideo } from "../../utils";

/**
 * Regular expression pattern for detecting is on Iphone.
 * @type {RegExp}
 * {i} is To make the regular expression case-insensitive
 */
const REGEX_IOS_USING_SAFARI: RegExp = /(iPhone|Mac|iPad).*Safari/i;

export default function AnimatedVideo() {
    const refVideo = useRef<HTMLVideoElement>(null);
    /**
     * Determines if the current browser can support video playback.
     * It returns `true` if the user agent is not from an iOS device using Safari.
     * To return a Gif because video tag doesn't work in Safari on iphone and Ipad.
     * We make touchpoint check because we can't make difference between iPad and Mac
     * @type {boolean}
     */
    const canSupportVideo: boolean = !REGEX_IOS_USING_SAFARI.test(navigator.userAgent) || navigator.maxTouchPoints <= 1;

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }
        refVideo.current.muted = true;
    }, []);

    if (!canSupportVideo) {
        return (
            <img
                src={getImage("presentation.gif")}
                alt="The Reach Up app running"
                className="gif__IOS"
                style={{
                    maxWidth: 360,
                    maxHeight: 600,
                }}
            />
        );
    }

    return (
        <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            ref={refVideo}
        >
            <source src={getVideo("ReachUp-anim.webm")} type="video/webm" />
            <source src={getVideo("ReachUp-anim.mp4")} type="video/mp4" />
        </video>
    );
}
