/**
 * Regarding the environment returns the proper URL of the app.
 * @param environment
 * @returns
 */
export const getAppLink = (environment: string | null): string => {
    if (!environment || environment === "prd") {
        // default case
        // production case
        return "https://app.reachup.app/";
    }

    return `https://app-${environment}.reachup.app/`;
};
