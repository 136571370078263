const PUBLIC_FOLDER: string | undefined = process.env.PUBLIC_URL;
const IMAGES_FOLDER: string = "images";
const ICONS_FOLDER: string = "icons";
const VIDEOS_FOLDER: string = "video";

export const getImage = (imageName: string): string => {
    return (`${PUBLIC_FOLDER}/${IMAGES_FOLDER}/${imageName}`);
}
export const getIcon = (iconName: string): string => {
    return (`${PUBLIC_FOLDER}/${ICONS_FOLDER}/${iconName}`);
}
export const getVideo = (videoName: string): string => {
    return (`${PUBLIC_FOLDER}/${VIDEOS_FOLDER}/${videoName}`);
}