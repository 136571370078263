import { Trans, useTranslation } from "react-i18next";
import "./Gamification.scss";
import { getIcon } from "../../utils";
import { Enclose } from "../Enclose/Enclose";
import { SpecialWord } from "../SpecialWord/SpecialWord";
import { useEffect, useRef, useState } from "react";

interface AnimatedNumberProps {
  value: number;
  duration: number;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = (props): JSX.Element => {
  const [displayValue, setDisplayValue] = useState<number>(0);
  const numberRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let startTimestamp: number | null = null;
    let animationFrameId: number | null = null;

    const step = (timestamp: number): void => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress:number = timestamp - startTimestamp;
      const progressValue:number = Math.min(progress / props.duration, 1);
      const currentValue:number = Math.floor(props.value * progressValue);
      setDisplayValue(currentValue);
      if (progress < props.duration) {
        animationFrameId = requestAnimationFrame(step);
      }
    };

    // Intersection Observer configuration
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        startTimestamp = null; // Reset the timestamp to start the animation again
        animationFrameId = requestAnimationFrame(step);
      } else {
        // Stop the animation when it goes out of view
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
          animationFrameId = null;
        }
      }
    }, options);

    if (numberRef.current) {
      observer.observe(numberRef.current);
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      observer.disconnect(); // Disconnect the Intersection Observer when the component unmounts
      //setDisplayValue(0); // Reset the display value when the component unmounts
    };
  }, [props.duration, props.value]);

  return <span className="game__box-number" ref={numberRef}>{displayValue} %</span>;
};

const Gamification = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <section className="Gamification">
      <span className="gamification__title">
        {t("Gamification_section_title")}{" "}
      </span>
      <h2>
        <Trans i18nKey={"Gamification_section_subtitle"}>
          <></>
          <Enclose />
        </Trans>
      </h2>
      <div className="gamification__content">
        <div className="game__box">
          <img src={getIcon("Flash.svg")} alt="flash icon" />
          <AnimatedNumber value={90} duration={1500} />
          <p>
            <Trans i18nKey={"Gamification_flash_description"}>
              <></>
              <SpecialWord />
              <></>
            </Trans>
          </p>
        </div>
        <div className="game__box">
          <img src={getIcon("Trophy.svg")} alt="Trophy icon" />
          <AnimatedNumber value={69} duration={1500} />
          <p>
            <Trans i18nKey={"Gamification_trophy_description"}>
              <></>
              <SpecialWord />
              <></>
            </Trans>
          </p>
        </div>
        <div className="game__box">
          <img src={getIcon("Heart.svg")} alt="flash icon" />
          <AnimatedNumber value={95} duration={1500} />
          <p>
            <Trans i18nKey={"Gamification_hearth_description"}>
              <></>
              <SpecialWord />
              <></>
            </Trans>
          </p>
        </div>
      </div>
    </section>
  );
};


export default Gamification;
