interface Props {
    height: number;
}

export function Ellipse(props: Props) {
    const parentWidth = 600;
    const rectangleHeight = props.height;
  
    const radius: number = (1 / 8) * (((parentWidth * parentWidth) / rectangleHeight) + 40 * rectangleHeight);
   
    return (
        <svg
            width={"100%"}
            height={props.height}
            viewBox={`0 0 ${parentWidth} ${props.height}`}
            preserveAspectRatio="none"
        >
            <circle
                cx={parentWidth / 2}
                cy={rectangleHeight - radius}
                r={radius}
                fill="#5562FF"
            />
        </svg>
    );
}
