import { useTranslation } from "react-i18next";
import "./Bottom.scss";
import Calendly from "../Calendly/Calendly";

const BottomScreen = () => {
    const { t } = useTranslation();
    return (
        <section className="Bottom">
            <div className="bottom__text">
                <h2> {t("Bottom_section_title")} </h2>
                <p>
                    {t("Bottom_section_description")}
                </p>
                <Calendly title={t("Button_ask_demo")} />
            </div>
        </section>
    );
};

export default BottomScreen;
