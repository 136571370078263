import "./SpecialWord.scss"

export function SpecialWord(props: {
  children?: string;
}): JSX.Element {


  return (
    <span className="special__word">{props.children}</span>
  );
}