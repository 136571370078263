import { Trans, useTranslation } from "react-i18next";
import { getImage } from "../../utils";
import "./TopScreen.scss";
import { Enclose } from "../Enclose/Enclose";
import { Substract } from "../Substract/Substract";
import { SpecialWord } from "../SpecialWord/SpecialWord";
import AnimatedVideo from "./AnimatedVideo";
import Calendly from "../Calendly/Calendly";

const TopScreen = () => {
  const { t } = useTranslation();
  const VideoId: string = "https://youtube.com/embed/d7cDxv7k-Qw?loop=1";

  return (
    <section className="TopScreen">
      <div className="banner ">
        <div className="text">
          <h1>
            <Trans i18nKey={"BoostYourTeam_title"}>
              <></>
              <Substract />
              <></>
            </Trans>
          </h1>
          <p>{t("BoostYourTeam_description")}</p>
          <Calendly title={t("Button_ask_demo")}/>
        </div>
        <div className="video__reach-up">
          <iframe
            title="Reach up presentation video"
            src={VideoId}
            height={"100%"}
            style={{
              borderRadius: 10,
              position: "relative",
              height: "100%",
              width: "100%",
            }}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      </div>

      {/* Why Reach Up content */}
      <div className="whyReachUp" id="why_reachUp">
        <div className="imageTop whyReachUp__img">
          <AnimatedVideo />
        </div>
        <div className="text whyReachUp__Text">
          <span className="subtitle">{t("WhyReachup_title")}</span>
          <h2>
            <Trans i18nKey={"WhyReachup_subtitle"}>
              <></>
              <Enclose />
              <></>
            </Trans>
          </h2>
          <p>
            <Trans i18nKey={"WhyReachup_description"}>
              <></>
              <SpecialWord />
              <></>
              <SpecialWord />
              <></>
              <SpecialWord />
              <></>
              <SpecialWord />
            </Trans>
          </p>
          <Calendly title={t("Start the Adventure")}/>
        </div>
      </div>

      {/* For Who content */}
      <div className="forWho" id="for_whom">
        <div className="forWho__title">
          <span className="subtitle">{t("ForWhom_section_title")}</span>
          <h2> {t("ForWhom_section_subtitle")} </h2>
        </div>
        <div className="forWho__container">
          {/* RESTAURANT CARD */}
          <div className="forWho__card forWho__card-restaurant">
            <div className="card__title card__title-restaurant">
              <h3> {t("ForWhom_restaurant")} </h3>
            </div>
            <img src={getImage("restaurant.png")} alt="waiter behind a bar" />
            <div className="card__description card__description-restaurant">
              <h3>{t("ForWhom_restaurant_title")}</h3>
              <p><Trans i18nKey={"ForWhom_restaurant_description"} /></p>
            </div>
          </div>
          {/* SHOP CARD */}
          <div className="forWho__card forWho__card-shop">
            <div className="card__title card__title-shop">
              <h3> {t("ForWhom_shop")} </h3>
            </div>
            <img
              src={getImage("women-shop.png")}
              alt="saleswoman in her store"
            />
            <div className="card__description card__description-shop">
              <h3>{t("ForWhom_shop_title")}</h3>
              <p><Trans i18nKey={"ForWhom_shop_description"} /></p>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default TopScreen;
