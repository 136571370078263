import { useTranslation } from "react-i18next";
import { getIcon, getImage } from "../../utils";
import "./Dgenious.scss";

const Dgenious = () => {
  const { t } = useTranslation();
  return (
    <section className="Dgenious" id="about">
      <div className="dgenious__content">
        <div className="dgenious__text">
          <h2> {t('About_section_subtitle')} </h2>
          <p>
            {t('About_section_description')}
          </p>
        </div>
        <div className="dgenious__team">
          <img src={getIcon("Nice.svg")} alt="" className="badge__team" />
          <img src={getImage("team.png")} alt="women using reach up in her store " className="dgenious__team--image" />
        </div>
      </div>
    </section>
  );
};

export default Dgenious;
