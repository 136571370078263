import BottomScreen from "../components/BottomScreen/BottomScreen";
import Dgenious from "../components/Dgenious/Dgenious";
import Footer from "../components/Footer/Footer";
import Gamification from "../components/Gamification/Gamification";
import Header from "../components/Header/Header";
import { PriceView } from "../components/Price/PriceView";
import Solution from "../components/Solution/Solution";
import TopScreen from "../components/TopScreen/TopScreen";

export function Spa() {
    return (
        <>
            <Header />
            <main>
                <TopScreen />
                <Solution />
                <PriceView />
                <Gamification />
                <BottomScreen />
                <Dgenious />
            </main>
            <Footer />
        </>
    );
}