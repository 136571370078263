import { useTranslation } from "react-i18next";
import { getIcon } from "../../utils";
import "./Footer.scss";
import Calendly from "../Calendly/Calendly";
import { APPLE_STORE_LINK, GOOGLEPLAY_STORE_LINK, LINKEDIN_LINK } from "../../config/constante";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="footer__content">
        <div className="footer-link" >
          <img src={getIcon("LogoFooter.svg")} alt="Logo footer" className="footer__logo" />
          <div className="footer__app-link-desktop" >
        <a href={APPLE_STORE_LINK} target="_blank" rel="noreferrer">
          <img src={getIcon("AppleStore.svg")} alt="Apple store logo" />
        </a>
        <a href={GOOGLEPLAY_STORE_LINK} target="_blank" rel="noreferrer">
          <img src={getIcon("GooglePlay.svg")} alt="Google Play logo" />
        </a>
      </div>
        </div>
        <div className="address__container">
           <address className="contact--info">
          <span> {t("Footer_link_talk")} </span>
          <button>
            <a href="mailto:contact@reachup.app">
              contact@reachup.app
            </a>
          </button>
        </address>
        <address className="contact--info">
          <span>{t("Footer_link_meet")} </span>
          <p>
            Avenue Louise 231
            <br />
            1050 Bruxelles
          </p>
        </address>
        <address className="contact--info">
          <span> {t("Footer_link_follow")}</span>
          <a href={LINKEDIN_LINK} target="_blank" rel="noreferrer" className="linkedin" >
            <img
              src={getIcon("Linkedin.svg")}
              alt="Icon linkedin"
              className="social__icon"
            />
          </a>
        </address>
        <div className="askForDemo">
          <span> {t("Footer_call_action")}</span>
          <Calendly title={t("Button_ask_demo")} />
        </div>
        </div>
       

        {/* <LanguageSwitch color={"#FDBD4D"} /> */}
      </div>
      <div className="footer__app-link" >
        <a href={APPLE_STORE_LINK} target="_blank" rel="noreferrer">
          <img src={getIcon("AppleStore.svg")} alt="Apple store logo" />
        </a>
        <a href={GOOGLEPLAY_STORE_LINK} target="_blank" rel="noreferrer">
          <img src={getIcon("GooglePlay.svg")} alt="Google Play logo" />
        </a>
      </div>
      <div className="underline" style={{
        height: 1,
        backgroundColor: "#FFFFFF26",
        margin: 10
      }} />
      <div className="copyright__container">
        <span className="copyright">{t("Copyright")}</span>
      </div>
    </footer>
  );
};

export default Footer;
