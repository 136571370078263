import { useSearchParams } from "react-router-dom";

/**
 * Retrieve an object with values filled from queryStringParameters
 * Return a field for all attributes (can be undefined)
 * @param attributes 
 * @returns 
 */
export const useParameters = <T extends string>(
    ...attributes: T[]
): Record<T, string | null> => {
    // get search parameters from the current location
    const [searchParams] = useSearchParams();

    const response: Partial<Record<T, string | null>> = {};
    // collect value per attribute
    attributes.forEach((attribute: T): void => {
        response[attribute] = searchParams.get(attribute);
    });

    return (response as Record<T, string | null>);
};
