import "./Header.scss";
import { useTranslation } from "react-i18next";
import { getIcon } from "../../utils";
import { useState } from "react";
import Calendly from "../Calendly/Calendly";

function Header() {
  const { t } = useTranslation();
  const [isNavExpanded, setIsNavExpanded] = useState<boolean>(false);

  return (
    <header>
      {/* V2 NAV */}
      <div className="mobile__header">

        <a href="/">
          <img src={getIcon("Logo-animated.svg")} alt="Logo ReachUp" />
        </a>
        <button
          className="hamburger"
        >
          <label htmlFor="check">
            <input type="checkbox" id="check" onClick={() => {
              setTimeout(() => setIsNavExpanded(!isNavExpanded), 120);
            }
            }
            />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </button>
      </div>

      <nav className={isNavExpanded ? "nav__mobile" : "nav__desktop"}>
        <ul>
          <li>
            <a href="#why_reachUp">
              {t("Nav_why_reachup")}
            </a>
          </li>
          <li>
            <a href="#for_whom">
              {t("Nav_for_who")}
            </a>
          </li>
          <li>
            <a href="#features">
              {t("Nav_fonctionality")}
            </a>
          </li>
          {/* <li>
            <a href="#price">
              {t("Nav_price")}
            </a>
          </li> */}
          <li>
            <a href="#about">
              {t("Nav_about")}
            </a>
          </li>
        </ul>
        {/* <LanguageSwitch color={"#5562FF"} />  */}
        <div className="callToAction">
          <Calendly title={t("Button_ask_demo")} />
           <button>
            <a href="mailto:contact@reachup.app">
              {
                t("Button_contact")
              }
              </a>
           </button>
        </div>
      </nav>
    </header>
  );
}

export default Header;