import { useTranslation } from 'react-i18next';
import { Ellipse } from '../../../components';
import { getImage } from '../../../utils';
import { useParameters } from '../useParameters';
import "./NewUser.scss";
import { useEffect } from 'react';
import { APPLE_STORE_LINK, GOOGLEPLAY_STORE_LINK, LINKEDIN_LINK } from '../../../config/constante';
import { getAppLink } from "../utils";

// page / component
// use the function typing
export function NewUser(): JSX.Element {
    const parameters = useParameters("environment", "user-name", "tmp-pass");
    const { t } = useTranslation();

    const appLink = getAppLink(parameters.environment);

    /**
     * Sets the background color of the body element to '#f6f6f6' when the component mounts.
     * Resets the background color when the component unmounts.
     */
    useEffect(
        () => {
            document.body.style.backgroundColor = '#f6f6f6';
            return () => {
                document.body.style.backgroundColor = '';
            };
        },
        []
    );

    return (
        <table className="mainTable">
            {/* <!-- HEADER --> */}
            <thead>
                <tr>
                    <th colSpan={2} className="ellipse-1">
                        <Ellipse height={120} />
                    </th>
                </tr>
            </thead>
            {/* <!-- END HEADER --> */}
            {/* <!-- MAIN CONTENT --> */}
            <tbody>
                <tr>
                    <td className="img">
                        <a href="https://reachup.app/" target="_blank" rel="noreferrer">
                            <img
                                src={getImage("Logo-min.png")}
                                alt="Reach Up logo"
                                width="209"
                                height="90"
                            />
                        </a>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="email_title" >
                        <p >
                            {
                                t("Email_newUser_title")
                            }
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="email_content new-user_email_content">
                        <p>{t("Email_newUser_subTitle")}</p>
                        <p >
                            {
                                t("Email_newUser_text")
                            }
                        </p>
                        <p >
                            {
                                t("Email_newUser_description")
                            }
                        </p>

                        <p style={{ textAlign: "center" }}>
                            ({t("Email_newUser_pwdValidity")})
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={2}
                        style={{ padding: 0, paddingTop: "30px" }}
                    >
                        <table className="email_content-table newUser-email_content_table">
                            <tbody>
                                <tr>
                                    <td className="label new-user_top">
                                        {
                                            t("Email_newUser_id")
                                        }
                                    </td>
                                    <td className="value new-user_top">{parameters['user-name']}</td>
                                </tr>
                                <tr>
                                    <td className="label new-user_bottom">
                                        {
                                            t("Email_newUser_pwd")
                                        }
                                    </td>
                                    <td className="value new-user_bottom">{parameters['tmp-pass']}</td>
                                </tr>
                                <tr className="new-user_to-app-section__row">
                                    <td colSpan={2} className="new-user_to-app-section__element">
                                        <a
                                            target="_blank"
                                            className="new-user_to-app-section__link"
                                            href={`${appLink}login?status=new_user&user=${parameters['user-name']}`}
                                        >{t("Email_newUser_link")}</a>
                                    </td>
                                </tr>
                                <tr className="new-user_to-app-section__row">
                                    <td
                                        colSpan={2}
                                        style={{
                                            paddingTop: 16,
                                        }}
                                        className="new-user_to-app-section__element"
                                    >
                                        <a
                                            target="_blank"
                                            className="new-user_to-app-section__link"
                                            href={`${appLink}login?status=new_user&user=${parameters['user-name']}`}
                                        >{`${appLink}login?status=new_user&user=${parameters['user-name']}`}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "start" }} >
                                        <a href={APPLE_STORE_LINK} target="_blank" rel="noreferrer">
                                            <img src={getImage("EmailAppleStore.png")} alt="Apple store logo" width={110} height={50} />
                                        </a>
                                    </td>
                                    <td style={{ textAlign: "end" }}>
                                        <a href={GOOGLEPLAY_STORE_LINK} target="_blank" rel="noreferrer">
                                            <img src={getImage("EmailGooglePlay.png")} alt="Google Play logo" width={110} height={50} />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ height: 1, textAlign: 'center' }}>
                        <div className="row-to-reduce"></div>
                    </td>
                </tr>
                <tr className="email_content-bottom">
                    <td >
                        <a
                            href="https://reachup.app/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={getImage("Logo.png")}
                                alt="Reach Up logo"
                                width="135"
                            />
                        </a>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <a
                            href={LINKEDIN_LINK}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={getImage("linkedin.png")}
                                alt="Linkedin logo"
                                width="21"
                                height="23"
                            />
                        </a>
                    </td>
                </tr>
                {/* <!-- END MAIN CONTENT --> */}
                {/* <!-- FOOTER --> */}
                <tr>
                    <td colSpan={2}>
                        <table className="footer_table">
                            <tbody>
                                <tr>
                                    <td className="footer-value" >
                                        <a
                                            href="https://reachup.app/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="reachUp-link"
                                        >
                                            {
                                                t("Email__footer_ReachUp")
                                            }
                                        </a>
                                    </td>
                                    <td className="footer-value">&bull;</td>
                                    <td className="footer-value">
                                        <p>{t("Email__footer_adress")} </p>
                                    </td>
                                    <td className="footer-value">&bull;</td>
                                    <td className="footer-value">
                                        <p>{t("Email__footer_city")} </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                {/* <!-- END FOOTER --> */}
            </tbody>
        </table>
    );
};
