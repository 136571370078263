import { useState } from "react";
import { Slider, Substract } from "..";
import {
  DEFAULT_NB_STORES,
  MIN_STORES,
  MAX_STORES,
  OPTIONS,
  BASE_PRICES,
  PricingFeature,
  formatNumberWithRounding,
} from "./constantes";
import { computePrice, getMonthlyPrice, getYearlyPrice } from "./utils";
import { LicenceCommit, LICENCES_TYPE, LicenceType } from "./types";
import "./PriceView.scss";
import { Trans, useTranslation } from "react-i18next";
import Calendly from "../Calendly/Calendly";

export function PriceView(): JSX.Element {
  const [nbStores, setNbStores] = useState<number>(DEFAULT_NB_STORES);
  const [isMonthlyChecked, setIsMonthlyChecked] = useState<boolean>(true);
  const [isAnnualChecked, setIsAnnualChecked] = useState<boolean>(false);
  const { t } = useTranslation();

  const renderPriceCards = () => {
    const licenceCommit: LicenceCommit = isMonthlyChecked
      ? LicenceCommit.MONTHLY
      : LicenceCommit.YEARLY;

    return (
      <div key={licenceCommit} className="commitContainer">
        {LICENCES_TYPE.map((licenceType: LicenceType) => {
          const cardKey = `${licenceCommit} ${licenceType}`;

          //DISCOUNT LOGIC
          const basePrice: number = BASE_PRICES[licenceCommit][licenceType] as number;
          const price: number = computePrice(
            nbStores,
            basePrice
          );
          const pricePerLicence: number = price / nbStores;

          return (
            <div key={cardKey} className="licenceContainer">
              <span className="licenceName">{t(licenceType)}</span>
              <div className="price-month">
                {((): JSX.Element => {
                  if (pricePerLicence < basePrice && !isAnnualChecked) {
                    return (
                      <>
                        <span className="line">
                          {formatNumberWithRounding(basePrice)}
                        </span>
                        <span className="price__picked">
                          {formatNumberWithRounding(pricePerLicence)}
                        </span>
                      </>
                    );
                  }

                  if (pricePerLicence < basePrice && isAnnualChecked) {
                    return (
                      <>
                        <span className="line">
                          {formatNumberWithRounding(basePrice)}
                        </span>
                        <span className="price__picked">
                          {formatNumberWithRounding(pricePerLicence)}
                        </span>
                      </>
                    );
                  }

                  return (
                    <span className="price__picked">
                      {formatNumberWithRounding(pricePerLicence)}
                    </span>
                  );
                })()}

                <span>{t("Slider_user_per_month")}</span>
              </div>
              {/* Resume Price */}
              <span className="price-year">
                {((): JSX.Element => {
                  if (licenceCommit === LicenceCommit.MONTHLY) {
                    return (
                      <>
                        <Trans
                          i18nKey={"Slider_per_month"}
                          values={{
                            priceMonth: getMonthlyPrice(nbStores, licenceType),
                          }
                          }
                        />
                      </>
                    );
                  }
                  // yearly
                  return (
                    <>
                      <Trans
                        i18nKey={"Slider_per_year"}
                        values={{
                          priceYear: getYearlyPrice(nbStores, licenceType),

                        }}
                      />
                    </>
                  );
                })()}
              </span>
              <span className="slider__user">
                <Trans
                  i18nKey={"Slider_user_number_result"}
                  values={{ userNumber: nbStores }}
                />
              </span>
              <Calendly title={t("Button_ask_demo")} />
              <div className="underline" />
              <div className="options">
                <ul className="accordion">
                  {OPTIONS[licenceType].map(
                    (option: PricingFeature): JSX.Element => {
                      const id: string = option.name;
                      const groupName: string = "Options per package "; // Set a common name for each group
                      return (
                        <li key={id}>
                          <input type="radio" id={id} name={groupName} />
                          <label
                            htmlFor={id}
                            className={
                              option.description !== undefined
                                ? "label-with-content"
                                : ""
                            }
                          >
                            <Trans i18nKey={option.name}>
                              <></>
                              <Substract />
                            </Trans>
                            {option.extraPricing === undefined ? null : (
                              <span id="price__option">
                                +{formatNumberWithRounding(option.extraPricing)}
                              </span>
                            )}
                          </label>
                          <div className={"collapse"}>
                            <p>{t(option.description ?? "")}</p>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleMonthlyClick = () => {
    setIsMonthlyChecked(true);
    setIsAnnualChecked(false);
  };

  const handleAnnualClick = () => {
    setIsMonthlyChecked(false);
    setIsAnnualChecked(true);
  };

  return (
    <section className="Price" id="price">
      <span className="price__title"> {t("Price_section_title")} </span>
      <h2>
        <Trans i18nKey={"Price_section_subtitle"}>
          <></>
          <Substract />
        </Trans>
      </h2>

      {/* <Slider
        currentValue={nbStores}
        min={MIN_STORES}
        max={MAX_STORES}
        step={1}
        onChange={setNbStores}
      />

      <div className="monthlyOrAnnual">
        <button
          onClick={handleMonthlyClick}
          className={isMonthlyChecked ? "checked" : "monthly"}
        >
          {t("Price_button_monthly")}
        </button>
        <button
          onClick={handleAnnualClick}
          className={isAnnualChecked ? "checked" : "annual"}
        >
          {t("Price_button_year")} <span>- 20%</span>
        </button>
      </div>
      <div className="pricingCards__container">{renderPriceCards()}</div> */}
      <div className="price__footer">
        <span>{t("Price_call_to_action")}</span>
        <Calendly title={t("Contact-us")} />
      </div>
    </section>
  );
}
