import { getIcon } from "../../utils";
import { Enclose } from "../Enclose/Enclose";
import "./Solution.scss";
import { Trans, useTranslation } from "react-i18next";
const Solution = () => {
  const { t } = useTranslation();
  return (
    <section className="Solution" id="features">
      <span className="solution__title"> {t("Solution_section_title")} </span>
      <h2>
        <Trans 
        i18nKey={"Solution_section_subtitle"}>
          <></>
          <Enclose/>
          <></>
        </Trans>
      </h2>
      <div className="solutionContent">
        <div className="icn__card">
          <div className="icn">
            <img src={getIcon("Time.svg")} alt="icon time" />
          </div>
          <h3> {t("Solution_realTime_title")} </h3>
          <p>
           {t("Solution_realTime_description")}
          </p>
        </div>
        <div className="icn__card">
          <div className="icn">
            <img src={getIcon("Game.svg")} alt="icon game" />
          </div>
          <h3>{t("Solution_gaming_title")}</h3>
          <p>
            {t("Solution_gaming_description")}
          </p>
        </div>
        <div className="icn__card">
          <div className="icn">
            <img src={getIcon("Ia.svg")} alt="icon artifical intelligence" />
          </div>
          <h3>{t("Solution_intelligence_title")}</h3>
          <p>
           {t("Solution_intelligence_description")}
          </p>
        </div>
        <div className="icn__card">
          <div className="icn">
            <img src={getIcon("Chart.svg")} alt="icon chart" />
          </div>
            <h3>{t("Solution_impact_title")}</h3>
            <p>
             {t("Solution_impact_description")}
            </p>
        </div>
        <div className="icn__card">
          <div className="icn">
            <img src={getIcon("Brain.svg")} alt="icon brain" />
          </div>
          <h3>{t("Solution_science_title")}</h3>
          <p>
            {
              t("Solution_science_description")
            }
          </p>
        </div>
      </div>
    </section>
  );
};

export default Solution;
