import { useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18nOptions } from "../i18n";

interface Props {
    readonly setIsLoaded: () => void;
}

export function Configurator(props: Props): JSX.Element {
    useEffect((): void => {
        // setup language
        i18n
            .use(initReactI18next)
            .init(i18nOptions);

        i18n.changeLanguage("FR");

        props.setIsLoaded();
    }, [props]);

    return (
        <div>Loading</div>
    )
};