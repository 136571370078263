import { getIcon } from "../../utils";
import "./404.scss";

export const Error = (): JSX.Element => {
  return (
    <div id="notfound">
    
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            4<span></span>4
          </h1>
        </div>
        <h2>Oops! Page non trouvée</h2>
        <a href="/" className="scaled__button come__back">
          Retour sur le site
        </a>
        <a href="/">
        <img src={getIcon("LogoFooter-animated.svg")} alt="logo de reach up" width={200} />
      </a>
      </div>
    </div>
  );
};
