
interface Range
{
    minimum: number;
    maximum: number;
}

function random(this: Range): number
{
    return Math.random() * (this.maximum - this.minimum) + this.minimum;
}

export const Configuration =
{
    duration: {
        minimum: 1000,
        maximum: 2000,
        generate: random,
    },

    destinationX: {
        minimum: 300,
        maximum: 500,
        generate: random,
    },

    destinationY: {
        minimum: -300,
        maximum:  300,
        generate: random,
    },

    translate: {
        minimum: -100,
        maximum:    0,
        generate: random,
    },

    rotation: {
        minimum: -60,
        maximum:  60,
        generate: random,
    },

    scale: {
        minimum: 0.75,
        maximum: 1.25,
        generate: random,
    }

} satisfies Record<
    string, Range & {
        generate: typeof random
    }
>;
