import { useState } from 'react';
import { Configurator } from "./config";
import "./App.scss";
import "./index.scss";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { Error, Privacy, Spa, ForgotPassword, NewUser } from './pages';
import { NewUserSso } from "./pages/email/newUser/Sso";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Spa />,
    },
    {
        path: "privacy",
        element: <Privacy />,
    },
    // path is within an url
    // no need to use camelCase
    {
        path: "forgot-password",
        element: <ForgotPassword />,
    },
    // path is within an url
    // no need to use camelCase
    {
        path: "new-user",
        element: <NewUser />,
    },
    {
        path: "new-user-sso",
        element: <NewUserSso />,
    },
    // default routing policy
    // should always be at the end
    {
        path: "*",
        element: <Error />,
    },
]);

function App() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    if (!isLoaded) {
        return (
            <Configurator
                setIsLoaded={(): void => {
                    setIsLoaded(true);
                }}
            />
        );
    }
    return (
        <RouterProvider router={router} />
    );
}

export default App;
