import { PopupButton } from 'react-calendly';

interface PropsCalendlyBtn {
    title: string;
}

const Calendly = (props: PropsCalendlyBtn) => {
    return (
        <PopupButton
            url="https://calendly.com/mvanlierde/decouverte-reach-up-avec-martin"
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root") as HTMLElement}
            text={props.title}
        />
    );
};

export default Calendly;
