import React from "react";

import { Configuration } from "./configuration";

import "./particle.scss";

interface ParticleProps
{
    id: number;
}

interface ParticleState
{
    opacity: number;

    x: number;
    y: number;
}

export class Particle extends React.Component<ParticleProps, ParticleState>
{
    public override state: ParticleState =
    {
        opacity: 1,

        x: 0,
        y: 0,
    };

    private start!:     number;
    private duration!:  number;
    private isRunning!: boolean;

    private translate: string;
    private rotate:    string;
    private scale:     string;

    private destination!:
    {
        x: number;
        y: number;
    };

    public constructor(props: ParticleProps)
    {
        super(props);

        this.reset();

        this.translate = `0 ${Configuration.translate.generate()}%`;
        this.rotate    = `${Configuration.rotation.generate()}deg`;
        this.scale     = `${Configuration.scale.generate()}`;
    }

    public reset(): void
    {
        this.start     = performance.now();
        this.duration  = Configuration.duration.generate();
        this.isRunning = true;

        this.destination =
        {
            x: Configuration.destinationX.generate(),
            y: Configuration.destinationY.generate(),
        };
    }

    public isDone(): boolean
    {
        return !this.isRunning;
    }

    /**
     * @returns `true` if the animation is done, `false` otherwise.
     */
    public animate(timeStamp: number): boolean
    {
        if (!this.isRunning)
        {
            return true;
        }

        const percentage = (timeStamp - this.start) / this.duration;

        if (percentage > 1)
        {
            if (this.isRunning)
            {
                /*
                 * To be sure that the particle is not visible.
                 */
                this.setState(
                {
                    opacity: 0,

                    x: this.destination.x,
                    y: this.destination.y,
                });
            }

            this.isRunning = false;

            return true;
        }

        this.setState(
        {
            opacity: 1 - percentage,

            x: this.destination.x * percentage,
            y: this.destination.y * percentage,
        });

        return false;
    }

    public override render(): React.ReactNode
    {
        return (
            <img
                key={this.props.id}
                src="/logo.svg"
                className="particle"
                style={{
                    opacity: this.state.opacity,

                    marginLeft: `${this.state.x}px`,
                    marginTop:  `${this.state.y}px`,

                    translate: this.translate,
                    rotate:    this.rotate,
                    scale:     this.scale,
                }}
            />
        );
    }
}
