import React from "react";

import { Particle } from "./Particle";

import "./particle.scss";

interface ParticlesState
{
    particles:
    {
        reference: React.RefObject<Particle>;
        element:   JSX.Element;
    }[];
}

export class Particles extends React.Component<{}, ParticlesState>
{
    public override state: ParticlesState =
    {
        /*
         * For performance's sake (I think), we will keep all particles whether
         * they are running or not until every particle animations are done. By
         * doing so we allow particles to be reused by resetting them (their
         * state and thus their animation) instead of creating new ones.
         */
        particles: [],
    };

    private isRunning: boolean = false;

    private lastId: number = 0;

    public addParticle = (count: number = 1) =>
    {
        const particles: typeof this.state.particles = [];

        /*
         * First check if some particles can be reset.
         */
        for (const particle of this.state.particles)
        {
            if (count > 0 && particle.reference?.current?.isDone())
            {
                particle.reference.current.reset();

                --count;
            }

            particles.push(particle);
        }

        /*
         * Otherwise add new particles.
         */
        while (count > 0)
        {
            const reference = React.createRef<Particle>();

            /*
             * Even with an unique ID we still get this error: `Each child in a
             * list should have a unique "key" prop.`. With a `console.log()` we
             * can see that the `Particle` constructor is called twice...
             * I don't know why.
             *
             * FIXME.
             */
            const element = <Particle ref={reference} id={++this.lastId}/>

            particles.push(
            {
                reference,
                element,
            });

            --count;
        }

        this.setState(
        {
            particles,
        });
    };

    /**
     * Why all of this and not a mere CSS `keyframes` animation ? Because I
     * wanted the lifetime and the destination of each particle random and that
     * is not feasible with CSS only. With `keyframes` properties are hardcoded.
     * However, it would have been so much simpler and easier: just put to a
     * particle a CSS class with an `animation` property and bind it with
     * `onAnimationEnd` to then trigger the parent. But why make things simple?
     */
    private animate = (timeStamp: number) =>
    {
        let numberOfRunningParticles: number = 0;

        for (const particle of this.state.particles)
        {
            if (!particle.reference.current?.animate(timeStamp))
            {
                numberOfRunningParticles += 1;
            }
        }

        if (numberOfRunningParticles <= 0)
        {
            this.isRunning = false;

            /*
             * Reset all particles if there is no more to animate.
             */
            if (this.state.particles.length > 0)
            {
                this.setState(
                {
                    particles: [],
                });
            }
        }
        else
        {
            window.requestAnimationFrame(this.animate);
        }
    };

    public override componentDidUpdate(): void
    {
        if (!this.isRunning && this.state.particles.length)
        {
            this.isRunning = true;

            window.requestAnimationFrame(this.animate);
        }
    }

    public override render(): React.ReactNode
    {
        return (
            <div className="particles">
                {this.state.particles.map(particle => particle.element)}
            </div>
        );
    }
}
