export enum LicenceCommit {
    MONTHLY = "Monthly",
    YEARLY = "Yearly"
};

export const LICENCES_COMMIT: Array<LicenceCommit> = [
    LicenceCommit.MONTHLY,
    LicenceCommit.YEARLY
];

export enum LicenceType {
    STARTER = "Price_type_starter",
    PREMIUM = "Price_type_premium",
    ENTERPRISE = "Price_type_entreprise"
};

export const LICENCES_TYPE: Array<LicenceType> = [
    LicenceType.STARTER,
    LicenceType.PREMIUM,
    LicenceType.ENTERPRISE
];
